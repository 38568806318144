import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { lighten } from 'polished';
import moize from 'moize';

import counter from './Counter';
import routes from './../routes.const';
import history from './../history';
import categoriesDictionary from './../categories.const';
import { getURLSegments } from './../utils';
import { screenSizes } from './../constants/styles.const';
import mainNav from './../constants/menuItems.const';

const MainMenu = moize(({ navItems, pageUri }) => {
  return (
    <MainMenuContainer delay={counter.isFirstTime('mainnav') ? 0.8 : 0}>
      {navItems.map((item) => {
        const isSelected = item.uri === pageUri;
        return (
          <NavItem key={item.id}>
            <Link to={`/${item.uri}`} className={classNames({ selected: isSelected })}>
              {item.label}
            </Link>
          </NavItem>
        );
      })}
    </MainMenuContainer>
  );
});

const SubMenu = ({ categories, selectedCategoryUri, isShowing }) => {
  const subMenuClassName =
    counter.isFirstTime('subMenu') && isShowing ? 'firstLoad' : isShowing ? 'show' : 'hide';

  return (
    <SubMenuContainer delay={counter.isFirstTime('sub') ? 1 : 0} className={subMenuClassName}>
      {categories.map((item) => {
        const isSelected = item.uri === selectedCategoryUri;
        return (
          <SubNavItem key={item.id}>
            <Link
              to={`${routes.PORTFOLIO}/${item.uri}`}
              className={classNames({ selected: isSelected })}
            >
              {item.label}
            </Link>
          </SubNavItem>
        );
      })}
    </SubMenuContainer>
  );
};

const Navigation = (props) => {
  const [mainNavItem, category] = getURLSegments(history.location.pathname);

  const isSubCatShowing = mainNavItem === 'portfolio';

  const categories = Object.values(categoriesDictionary);

  // if (isSubCatShowing && !category) {
  //   const defaultCategory = categories[0].uri;
  //   return <Redirect to={`${routes.PORTFOLIO}/${defaultCategory}`} />;
  // }

  return (
    <NavContainer>
      <MainMenu navItems={mainNav} pageUri={mainNavItem} />
      <SubMenu categories={categories} selectedCategoryUri={category} isShowing={isSubCatShowing} />
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  display: none;
  @media (min-width: ${screenSizes.portrait}) {
    display: block;
  }
`;

const NavItem = styled.li`
  color: white;
  list-style-type: none;
  display: inline-block;
  margin-left: 15px;
  &:first-child {
    margin-left: 0;
  }
  a {
    font-size: 1.1em;
    text-decoration: none;
    color: #b8c4d6;
    // padding-bottom: 1px;
    &.selected {
      color: white;
      // border-bottom: 1px solid #587476;
    }
    &:hover {
      color: ${lighten(0.07, '#b8c4d6')};
    }
  }
`;

const SubNavItem = styled(NavItem)`
  a {
    font-size: 0.95em;
    color: #98a9c3;
    &.selected {
      color: white;
    }
  }
`;

const MainMenuContainer = styled.ul`
  padding: 0;
  margin: 0;
  text-align: center;

  opacity: 0;
  animation: opacityFadeIn 1s ease-out 1 forwards;
  animation-delay: ${(props) => props.delay || '0'}s;
`;

const SubMenuContainer = styled.ul`
  padding: 0;
  margin: 3px 0 0 0;
  text-align: center;

  opacity: 0
  transition: opacity 0.5s ease;

  &.firstLoad{
    animation: opacityFadeIn 1s ease-out 1 forwards;
    animation-delay: 1.2s;
  }
  &.show{
    opacity: 1
  }
  &.hide{
    opacity: 0
  }
`;

export default Navigation;
