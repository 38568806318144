import React from 'react';

import SiteLogo from './SiteLogo.component';
import Navigation from './Navigation.component';

const Header = props => {
  return (
    <div>
      <SiteLogo />
      <Navigation />
    </div>
  );
};

export default Header;
