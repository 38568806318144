import React from 'react';
import styled from 'styled-components';

const PlayPause = props => {
  const { onClick, isPlaying } = props;
  return (
    <Container onClick={onClick}>
      {!isPlaying && <PlayButton>play</PlayButton>}
      {isPlaying && <PauseButton>pause</PauseButton>}
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  display: block;
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  top: 5px;
`;

const PlayButton = styled.div`
  width: 30px;
  height: 30px;
  text-indent: -1000px;
  overflow: hidden;
  display: block;
  position: absolute;
  cursor: pointer;
  &:before {
    margin-left: -4px;
    margin-top: -10px;
    top: 50%;
    left: 50%;
    content: ' ';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 14px;
    border-color: transparent transparent transparent #a0d1d9;
  }
`;

const PauseButton = styled.div`
    text-indent: -1000px;
    overflow: hidden;
    display: block;
    position: absolute;
    width: 14px;
    height: 20px;
    margin:0;
    padding:5px 8px;
    cursor: pointer;
    &:before{
      display: block;
      content: " ";
      width: 4px;
      height: 20px;
      background-color: transparent;
      border-left: 6px solid #A0D1D9;
      border-right: 6px solid #A0D1D9;
    }
  }
`;

export default PlayPause;

// #play-pause{
//   display: block;
//   position: relative;
//   margin: 0 auto;
//   width: 30px;
//   height: 30px;
//   top: -5px;
//   #pause-button{
//     display: block;
//     position: absolute;
//     width: 14px;
//     height: 20px;
//     margin:0;
//     padding:5px 8px;
//     cursor: pointer;
//     &:before{
//       display: block;
//       content: " ";
//       width: 4px;
//       height: 20px;
//       background-color: transparent;
//       border-left: 6px solid #A0D1D9;
//       border-right: 6px solid #A0D1D9;
//     }
//   }
//   #play-button{
//     width: 30px;
//     height: 30px;
//     text-indent: -1000px;
//     overflow: hidden;
//     display: block;
//     position: absolute;
//     cursor: pointer;
//     &:before{
//       margin-left:-4px;
//       margin-top:-10px;
//       top:50%;
//       left:50%;
//       content:' ';
//       display: block;
//       position: absolute;
//       width: 0;
//       height: 0;
//       border-style: solid;
//       border-width: 10px 0 10px 14px;
//       border-color: transparent transparent transparent #A0D1D9;
//     }
//   }
// }
