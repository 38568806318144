import { apiResourceCollection, getResource, ActionName } from 'api-resource-collection';
import categories from './../categories.const';
import { objKeyValues, arrObjectValues } from 'api-resource-collection';
import { getThumbnailSrc } from './../utils';

const getEndpoint = (portfolio) => {
  const portfolioId = categories[portfolio].portfolioId;
  const baseURL = `${process.env.REACT_APP_API_URL}/users/${process.env.REACT_APP_VIMEO_USER_ID}`;
  const fields =
    'fields=uri,name,description,pictures.uri,pictures.sizes,files&sort=manual&per_page=100';
  return `${baseURL}/portfolios/${portfolioId}/videos?${fields}`;
};

const options = {
  token: process.env.REACT_APP_VIMEO_AUTH_TOKEN,
  userId: process.env.REACT_APP_VIMEO_USER_ID,
};

const dataTransform = (data) => {
  const dataWithIDs = [];
  let i = 0;
  data.forEach((item) => {
    dataWithIDs.push({
      index: i,
      id: item.uri.match(/\d+$/)[0],
      description: item.description,
      name: item.name,
      thumbnail: getThumbnailSrc(item.pictures.sizes),
      videoUrl: item.files[0].link,
    });
    i++;
  });
  return objKeyValues(dataWithIDs);
};

const resourceOptions = {
  transforms: [dataTransform, arrObjectValues],
};

export const portfolio = {
  television: apiResourceCollection('television', options),
  music: apiResourceCollection('music', options),
  games: apiResourceCollection('games', options),
  commercials: apiResourceCollection('commercials', options),
  film: apiResourceCollection('film', options),
  digital: apiResourceCollection('digital', options),
};

portfolio.television.addResource(
  getResource(ActionName.GET_ITEMS, getEndpoint('television'), resourceOptions)
);
portfolio.music.addResource(
  getResource(ActionName.GET_ITEMS, getEndpoint('music'), resourceOptions)
);
portfolio.games.addResource(
  getResource(ActionName.GET_ITEMS, getEndpoint('games'), resourceOptions)
);
portfolio.commercials.addResource(
  getResource(ActionName.GET_ITEMS, getEndpoint('commercials'), resourceOptions)
);
portfolio.film.addResource(getResource(ActionName.GET_ITEMS, getEndpoint('film'), resourceOptions));
portfolio.digital.addResource(
  getResource(ActionName.GET_ITEMS, getEndpoint('digital'), resourceOptions)
);

// video
// `https://api.vimeo.com/videos/375231232?fields=files`

// `https://player.vimeo.com/external/375231232.sd.mp4?s=31dd630ea0aaf76f21eb771025df38d767a8f23e&profile_id=165&oauth2_token_id=1271835951`
