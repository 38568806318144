import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';

import routes from './../routes.const';
import history from './../history';
import categoriesDictionary from './../categories.const';
import { getURLSegments } from './../utils';
import { screenSizes } from './../constants/styles.const';
import mainNav from './../constants/menuItems.const';

const MobileMenu = (props) => {
  const [mainNavItem, category] = getURLSegments(history.location.pathname);

  const categories = Object.values(categoriesDictionary);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [visibility, setVisibility] = useState('hidden');

  const [displayClass, setDisplayClass] = useState('hide');

  const onMenuButtonClicked = () => {
    setIsMenuOpen((open) => !open);
  };

  const open = useCallback(() => {
    setVisibility('visible');
    setDisplayClass('show');
  }, []);

  const close = useCallback(() => {
    setTimeout(() => {
      setVisibility('hidden');
    }, 400);
    setDisplayClass('hide');
  }, []);

  const onBackgroundClicked = (e) => {
    setIsMenuOpen(false);
  };

  const onLinkClicked = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      open();
    } else {
      close();
    }
  }, [isMenuOpen, open, close]);

  return (
    <Container>
      <Background className={displayClass} onClick={onBackgroundClicked} visibility={visibility} />
      <NavItems className={displayClass}>
        <ul>
          {mainNav.map((item) => {
            const isSelected = item.uri === mainNavItem;
            return (
              <NavItem key={item.id}>
                <Link
                  onClick={onLinkClicked}
                  to={`/${item.uri}`}
                  className={classNames({ selected: isSelected })}
                >
                  {item.label}
                </Link>

                {item.uri === 'portfolio' && (
                  <SubMenu>
                    {categories.map((item) => {
                      const isSelected = item.uri === category;
                      return (
                        <SubNavItem key={item.id}>
                          <Link
                            onClick={onLinkClicked}
                            to={`${routes.PORTFOLIO}/${item.uri}`}
                            className={classNames({ selected: isSelected })}
                          >
                            {item.label}
                          </Link>
                        </SubNavItem>
                      );
                    })}
                  </SubMenu>
                )}
              </NavItem>
            );
          })}
        </ul>
      </NavItems>

      <MenuButton isMenuOpen={isMenuOpen} onClick={onMenuButtonClicked} />
    </Container>
  );
};

const Container = styled.div`
  display: block;
  @media (min-width: ${screenSizes.portrait}) {
    display: none;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 10;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: auto;
  visibility: ${(props) => props.visibility};
  opacity: 0;
  transition: opacity 0.3s ease;

  &.show {
    opacity: 1;
  }
  &.hide {
    opacity: 0;
    transition-delay: 0.1s;
  }
`;

const MenuButton = styled.div`
  pointer-events: auto;
  width: 52px;
  height: 52px;
  overflow: hidden;
  text-indent: -410px;
  background-image: url('/images/buttons.png');
  background-position: ${(props) => (props.isMenuOpen ? '0px -52px' : '0px 0px')};
  position: fixed;
  bottom: 25px;
  margin: 0 0 0 50%;
  left: -26px;
  cursor: pointer;
  &:hover {
    background-position: ${(props) => (props.isMenuOpen ? '-52px -52px' : '-52px 0')};
  }
  @media screen and (min-width: @size-portrait) {
    display: none;
  }
`;

const NavItems = styled.div`
  pointer-events: auto;
  background: #18181d;
  position: absolute;

  width: 100%;
  bottom: 0;

  ul {
    margin: 0;
    padding: 0 0 100px 0;
    li {
      ul {
        padding: 0;
      }
    }
  }
  max-height: 100%;
  overflow-y: scroll;

  transform: translateY(100%);
  transition: transform 0.3s ease;

  &.show {
    transform: translateY(0%);
    transition-delay: 0.1s;
  }
  &.hide {
    transform: translateY(100%);
  }
`;

const NavItem = styled.li`
  display: block;
  a {
    font-size: 1.1em;
    color: #98a9c3;
    &.selected {
      color: white;
    }
    text-decoration: none;
    display: block;
    padding: 12px;
    background-color: #1c1c22;
    border-bottom: 1px solid #000;
    border-top: 1px solid #222;
  }
`;

const SubMenu = styled.ul`
  margin: 0;
  padding: 0;
`;

const SubNavItem = styled.li`
  a {
    font-size: 18px;
    display: block;
    padding: 12px;
    background-color: #131317;
    border-bottom: 1px solid #000;
    border-top: 1px solid #222;
  }
`;

export default MobileMenu;
