import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { slugify } from './../utils';
import Credits from './Credits.component';
import { screenSizes, Title } from './../constants/styles.const';
import { isMobile } from './../utils';

const PortfolioItem = ({ name, description, id, thumbnail, onSelect, index, useFade }) => {
  const uri = slugify(name);

  const [isLoaded, setIsLoaded] = useState(false);

  const [itemClassName, setItemClassName] = useState(useFade ? 'hide' : 'show');

  const isMob = isMobile();

  const onImageLoaded = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    let timeout;
    if (useFade) {
      timeout = setTimeout(() => {
        setItemClassName('show');
      }, index * 50);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLoaded, useFade, index]);

  return (
    <PortfolioItemContainer>
      <PortfolioItemContent
        className={itemClassName}
        onClick={onSelect.bind(this, uri)}
        role='button'
        aria-label={`watch video for ${name}`}
      >
        <Title isMobile={isMob}>{name}</Title>

        <ImageContainer>
          <Image alt={name} src={thumbnail} onLoad={onImageLoaded} />
          <PlayButton>play</PlayButton>
        </ImageContainer>

        <CreditsContainer>
          <Credits alwaysShow={false} description={description} />
        </CreditsContainer>
      </PortfolioItemContent>
    </PortfolioItemContainer>
  );
};

const PortfolioItemContainer = styled.div`
  text-align: center;
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 20px 0;

  @media (min-width: ${screenSizes.portrait}) {
    width: 327px;
    padding: 15px;
    margin: 0;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 293px;
  }
  @media (min-width: ${screenSizes.wide}) {
    width: 294px;
  }
`;

const PortfolioItemContent = styled.div`
  display: block;
  padding: 0 20px;
  position: relative;
  cursor: pointer;
  margin: 0 auto;

  max-width: 465px;

  @media (min-width: ${screenSizes.portrait}) {
    width: 327px;
    padding: 0;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 293px;
  }
  @media (min-width: ${screenSizes.wide}) {
    width: 294px;
  }

  opacity: 0;
  transition: opacity 1s ease;

  &.show {
    opacity: 1;
  }
`;

const CreditsContainer = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid #222;
`;

const ImageContainer = styled.div`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
  padding-top: calc(100% * (9 / 16));

  @media (min-width: ${screenSizes.portrait}) {
    width: 327px;
    height: 185px;
    padding-top: 0;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 293px;
    height: 164px;
  }
  @media (min-width: ${screenSizes.wide}) {
    width: 294px;
    height: 166px;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  @media (min-width: ${screenSizes.portrait}) {
    width: 327px;
    height: 185px;
    position: relative;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 293px;
    height: 164px;
  }
  @media (min-width: ${screenSizes.wide}) {
    width: 294px;
    height: 166px;
  }
`;

const PlayButton = styled.a`
  width: 62px;
  height: 62px;
  text-indent: -1000px;
  overflow: hidden;
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  margin-left: -31px;
  margin-top: -31px;
  top: 50%;
  left: 50%;
  cursor: pointer;
  border-radius: 31px 31px 31px 31px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  &:before {
    margin-left: -6px;
    margin-top: -14px;
    top: 50%;
    left: 50%;
    content: ' ';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 0 14px 18px;
    border-color: transparent transparent transparent white;
  }
`;

export default PortfolioItem;
