import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import Scrubber from './Scrubber.component';

const getScrubberPosition = (position, trackLength) => {
  return position * trackLength + 4;
};

const Progress = (props) => {
  const { updateScrubberPosition, onScrubStart, onScrubStop, loaded, position, width } = props;

  const trackLength = width - 18;

  const loadingBarWidth = loaded * width;

  const progressBarWidth = position * width;

  const initialPosition = getScrubberPosition(position, trackLength);

  const [scrubberPosition, setScrubberPosition] = useState(initialPosition);

  const [left, setLeft] = useState(0);

  const progressBarRef = useRef();

  useEffect(() => {
    const progressBarRect = progressBarRef.current.getBoundingClientRect();
    setLeft(progressBarRect.left);
  }, [loaded]);

  useEffect(() => {
    const scrPos = getScrubberPosition(position, trackLength);
    setScrubberPosition(scrPos);
  }, [position, trackLength]);

  return (
    <Container ref={progressBarRef} width={width}>
      <LoadingBar width={loadingBarWidth}></LoadingBar>
      <ProgressBar width={progressBarWidth}></ProgressBar>
      <Scrubber
        updateScrubberPosition={updateScrubberPosition}
        left={left}
        maxLength={width}
        position={scrubberPosition}
        onScrubStart={onScrubStart}
        onScrubStop={onScrubStop}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 4px 0;
  padding: 0;
  width: ${(props) => props.width + 'px' || '0px'};
  height: 2px;
  display: block;
  position: relative;
  background: #1b1b1b;
`;

const LoadingBar = styled.div`
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  width: ${(props) => props.width + 'px' || '0px'};
  height: 2px;
  display: block;
  background: #323232;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  background: #686868;
  width: ${(props) => props.width + 'px' || '0px'};
  height: 2px;
`;

export default Progress;
