import { SET_VOLUME } from './volume.action';

const initialState = 0.8;

const volume = (state = initialState, action) => {
  switch (action.type) {
    case SET_VOLUME:
      return action.volume;
    default:
      return state;
  }
};

export default volume;
