import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { clamp } from './../../utils';

const Scrubber = ({
  position,
  maxLength,
  left,
  updateScrubberPosition,
  onScrubStart,
  onScrubStop,
}) => {
  const [scrubberPosition, setScrubberPosition] = useState();

  const [isDragging, setIsDragging] = useState(false);

  const handleRef = useRef();

  const handleOffsetRef = React.useRef();

  useEffect(() => {
    if (!isDragging) {
      setScrubberPosition(position);
    }
  }, [position, isDragging]);

  const onMouseDown = (e) => {
    setIsDragging(true);
    document.addEventListener('mouseup', onScrubberUp, false);
    document.addEventListener('mousemove', onPositionUpdate, false);
    const handleRect = handleRef.current.getBoundingClientRect();
    handleOffsetRef.current = e.clientX - handleRect.left - 4;
    e.preventDefault();
    onScrubStart();
  };

  const onPositionUpdate = function (e) {
    const xPos = e.clientX - left - handleOffsetRef.current;
    const clampedXPos = clamp(4, maxLength - 14, xPos);
    setScrubberPosition(clampedXPos);

    const progress = clamp(0, 1, (clampedXPos - 4) / (maxLength - 18));
    updateScrubberPosition(progress);
  };

  const onScrubberUp = (e) => {
    setIsDragging(false);
    document.removeEventListener('mouseup', onScrubberUp);
    document.removeEventListener('mousemove', onPositionUpdate);
    onScrubStop();
  };

  return (
    <Container position={scrubberPosition}>
      <Handle ref={handleRef} onMouseDown={onMouseDown}></Handle>
    </Container>
  );
};

const Container = styled.div`  
  position: absolute;
  top: 0;
  left: ${(props) => props.position + 'px' || '0px'};
  width: 10px
  height: 2px;
  background: white;
`;

const Handle = styled.div`
  cursor: pointer;
  position: absolute;
  top: -4px;
  left: -4px;
  display: block;
  content: ' ';
  width: 18px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.2);
`;

export default Scrubber;
