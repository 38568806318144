const Counter = () => {
  const keys = {};
  const isFirstTime = key => {
    if (!keys[key]) {
      keys[key] = true;
      return true;
    }
    return false;
  };
  return {
    isFirstTime
  };
};

const counter = Counter();

export default counter;
