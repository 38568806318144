import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';

import routes from './routes.const';
import PortfolioScreen from './screens/PortfolioScreen.component';
import AboutScreen from './screens/AboutScreen.component';
import ContactScreen from './screens/ContactScreen.component';
import Header from './components/Header.component';
import Footer from './components/Footer.component';
import { footer } from './constants/styles.const';
import MobileMenu from './components/MobileMenu.component';

const App = () => {
  const { location } = useReactRouter();
  return (
    <AppContainer>
      <MainContent>
        <Header location={location} />
        <Switch location={location}>
          <Redirect exact from={routes.PORTFOLIO} to={`${routes.PORTFOLIO}/television`} />
          <Route path={routes.PORTFOLIO} component={PortfolioScreen} />
          <Route path={routes.ABOUT} component={AboutScreen} />
          <Route path={routes.CONTACT} component={ContactScreen} />
          <Redirect to={`${routes.PORTFOLIO}/television`} />
        </Switch>

        <Push />
      </MainContent>
      <MobileMenu />
      <Footer />
    </AppContainer>
  );
};

const AppContainer = styled.main``;

const MainContent = styled.div`
  min-height: 100%;
  margin-bottom: ${footer.margin};
`;

const Push = styled.div`
  margin-top: 30px;
  height: ${footer.height};
`;

export default App;
