import React, { useRef } from 'react';
import styled from 'styled-components';

const VolumeControl = props => {
  const { setVolume, volume } = props;

  const volumeBarWidth = Math.min(Math.round(volume * 38), 38);

  const volumeRef = useRef();

  const onVolumeClick = e => {
    const rect = volumeRef.current.getBoundingClientRect();
    const pos = e.clientX - rect.left;
    const vol = Math.max(pos / 38, 0);
    setVolume(vol);
  };

  return (
    <Container ref={volumeRef} onClick={onVolumeClick}>
      <VolumeBars width={volumeBarWidth} />
    </Container>
  );
};

const Container = styled.div`
  display: block;
  position: absolute;
  width: 38px;
  height: 15px;
  top: 11px;
  right: 12px;
  cursor: pointer;
  background-image: url('/images/bars.png');
  background-size: 38px 30px;
  background-position: 0px 15px;
`;

const VolumeBars = styled.div`
  display: block;
  width: 38px;
  height: 15px;
  overflow: hidden;
  &:before {
    display: block;
    content: '';
    width: ${props => props.width + 'px' || '0px'};
    height: 15px;
    background-image: url('/images/bars.png');
    background-size: 38px 30px;
    background-position: 0px 0px;
  }
`;

export default VolumeControl;
