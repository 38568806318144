import { combineReducers } from 'redux';
import { portfolio } from './apiCollections';
import volume from './volume.reducer';

const appReducer = combineReducers({
  volume,
  ...portfolio.television.getReducers(),
  ...portfolio.games.getReducers(),
  ...portfolio.film.getReducers(),
  ...portfolio.commercials.getReducers(),
  ...portfolio.digital.getReducers(),
  ...portfolio.music.getReducers()
});

export default appReducer;
