import React from 'react';
import styled from 'styled-components';

import { colors, screenSizes } from './../constants/styles.const';

const Footer = (props) => {
  return (
    <Container>
      <Representation>
        Represented by:{' '}
        <FaberLogo href={'https://www.fabermusic.com/'} target="_blank">
          Faber Music Media
        </FaberLogo>
      </Representation>
    </Container>
  );
};

export default Footer;

const Container = styled.footer`
  background-color: black;
  font-size: 0.8em;
  color: ${colors.copyDarker};
  height: 70px;
  margin: 30px 0 0 0;
  z-index: 1;

  padding-bottom: 80px;
  @media (min-width: ${screenSizes.portrait}) {
    padding-bottom: 0;
  }
`;

const Representation = styled.div`
  padding-top: 20px;
`;

const FaberLogo = styled.a`
  display: inline-block;
  background: url('/images/faber_logo.png');
  width: 182px;
  height: 27px;
  text-indent: -10000px;
  position: relative;
`;
