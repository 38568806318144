import React from 'react';
import styled from 'styled-components';
import { uuid } from 'uuidv4';

import { colors, screenSizes } from './../constants/styles.const';

const Credits = (props) => {
  const { description, alwaysShow } = props;

  const credits = getCredits(description);

  return (
    <Container>
      {credits.map((credit) => {
        return (
          <Credit
            alwaysShow={alwaysShow}
            key={uuid()}
            term={credit.term}
            description={credit.description}
          />
        );
      })}
    </Container>
  );
};

const getCredits = (description) => {
  if (!description) {
    return [{ term: '', description: '' }];
  }
  const lines = description.split('\n');
  const credits = [];
  lines.forEach((line) => {
    const [term, description] = line.split(':');
    credits.push({
      term,
      description,
    });
  });
  return credits;
};

const Credit = (props) => {
  const { term, description, alwaysShow } = props;
  return (
    <CreditContainer alwaysShow={alwaysShow}>
      <Term>{term}</Term>
      <Description>{description}</Description>
    </CreditContainer>
  );
};

const Container = styled.div`
  font-size: 0.9em;
  background-color: #121214;
  padding: 10px 0 13px;
`;

const CreditContainer = styled.dl`
  margin: 0;

  ${(props) => props.alwaysShow} {
    @media (min-width: ${screenSizes.portrait}) {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
`;

const Term = styled.dt`
  display: inline-block;
  margin-right: 5px;
  color: #727272;
  margin: 0;
`;

const Description = styled.dd`
  display: inline-block;
  color: ${colors.copy};
  margin: 0 0 0 6px;
  color: #d8d8d8;
`;

export default Credits;
