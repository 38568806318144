import styled, { css, createGlobalStyle } from 'styled-components';

export const backgrounds = {
  fish: { url: '/images/backgrounds/fish.jpg' },
  riser: { url: '/images/backgrounds/riser.jpg' },
  rocks: { url: '/images/backgrounds/rocks.jpg' },
  speaker: { url: '/images/backgrounds/speaker.jpg' },
};

const getRandomBackground = () => {
  const bgs = Object.values(backgrounds);
  const index = Math.floor(Math.random() * bgs.length);
  return bgs[index].url;
};

export const screenSizes = {
  portrait: '768px',
  landscape: '1024px',
  wide: '1350px',
};

export const colors = {
  background: '#03070B',
  copy: '#F1F1F1',
  link: '#B8C4D6',
  copyDarker: '#bebebe',
};

export const footer = {
  height: '70px',
  margin: '-100px',
};

export const GlobalStyle = createGlobalStyle`

  @keyframes brightnessFadeIn {
    0%   {
      filter: brightness(0);
      -webkit-filter: brightness(0);
    }
    100% {
        filter: brightness(1);
        -webkit-filter: brightness(1);
    }
  }

  @keyframes opacityFadeIn {
    0%   {
      opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }



  html {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  html {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
  body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    color: ${colors.copy};    
    text-align: center;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: 300;
    font-size: 1.1em;
    line-height: 1.5em;
    position: relative;

    &:before{
      content: '';
      display: block;
      position: fixed;
      height: 100%;
      width: 100%;
      padding: 0;
      margin: 0;

      background-color: ${colors.background};
      background-image: url(${getRandomBackground()});
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      -webkit-filter: brightness(0);
      filter: brightness(0);
      animation: brightnessFadeIn 2s ease-out 1 forwards;

      z-index: -10
    }

  }
  #root{
    width: 100%;
    height: 100%;
    margin: 0;
  }
  main {
    height: 100%;
  }
`;

export const dottedBackground = `
  &:before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: url('/images/header_background.png');
  }
`;

export const transparentBackground = `
  &:before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: ${colors.background}
  }
`;

export const PageTitle = styled.h1`
  color: ${colors.copyDarker};
  margin: 15px 0 12px 0;
  padding: 0;
  font-weight: 200;
  font-size: 1.3em;
  text-align: center;

  opacity: 0;
  animation: opacityFadeIn 1s ease-out 1 forwards;
  animation-delay: 0.1s;
`;

export const Title = styled.h2`
  font-weight: 200;
  font-size: 1em;
  ${dottedBackground}
  color: ${colors.copyDarker};
  ${(props) =>
    props.isMobile &&
    css`
      ${transparentBackground}
      color: ${colors.copy};
    `}

  position: relative;

  position: relative;
  padding: 7px;
  margin-bottom: 1px;
  .dotted-bg;
  margin: 0;
`;
