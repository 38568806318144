import React from 'react';
import styled from 'styled-components';

import { screenSizes } from './../constants/styles.const';

const SiteLogo = props => {
  return (
    <LogoContainer>
      <Logo>Marc Sylvan Music</Logo>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  text-align: center;
`;
const Logo = styled.div`
  display: inline-block;
  text-indent: -1000px;
  overflow: hidden;
  background-image: url('/images/marc-sylvan-logo.png');
  background-size: cover;
  width: 260px;
  height: 77px;
  margin: 24px 20px 2px;
  @media screen and (min-width: ${screenSizes.portrait}) {
    margin: 44px 20px 21px;
  }

  opacity: 0;
  animation: opacityFadeIn 1s ease-out 1 forwards;
  animation-delay: 0.5s;
`;

export default SiteLogo;
