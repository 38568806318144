import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import * as volumeActions from './../state/volume.action';
import { dottedBackground } from './../constants/styles.const';
import ProgressBar from './player/ProgressBar.component';
import PlayPause from './player/PlayPause.component';
import VolumeControl from './player/VolumeControl.component';

const VideoPlayer = (props) => {
  const { thumbnail, src, isPaused } = props;

  const initialVolume = useSelector((state) => state.volume);

  const [volume, setVolume] = useState(initialVolume);

  const dispatch = useDispatch();

  const playerRef = useRef();

  const mp4Ref = useRef();

  const [percentLoaded, setPercentLoaded] = useState(0);

  const [position, setPosition] = useState(0);

  const [, setDuration] = useState(0);

  const [, setCurrentTime] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);

  const [, setDragPause] = useState(false);

  const dragPauseRef = useRef(false);

  useEffect(() => {
    if (isPaused) {
      playerRef.current.pause();
    }
  }, [isPaused]);

  const loadVideo = (src) => {
    mp4Ref.current.src = src;
    playerRef.current.load();
  };

  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, [setIsPlaying]);

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const onEnded = useCallback(() => {
    playerRef.current.load();
  }, [playerRef]);

  const onTimeUpdate = useCallback(() => {
    if (playerRef.current) {
      const percentPosition = playerRef.current.currentTime / playerRef.current.duration;
      setPosition(percentPosition || 0);
      setDuration(playerRef.current.duration);
      setCurrentTime(playerRef.current.currentTime);
      let bufferedEnd = 0;
      try {
        bufferedEnd = playerRef.current.buffered.end(0);
      } catch (error) {}
      const loaded = bufferedEnd / playerRef.current.duration;
      setPercentLoaded(loaded || 0);
    }
  }, [playerRef, setPosition, setDuration, setCurrentTime, setPercentLoaded]);

  const addListeners = useCallback(() => {
    playerRef.current.addEventListener('ended', onEnded, false);
    playerRef.current.addEventListener('play', onPlay, false);
    playerRef.current.addEventListener('pause', onPause, false);
    playerRef.current.addEventListener('timeupdate', onTimeUpdate, false);
  }, [playerRef, onEnded, onPlay, onPause, onTimeUpdate]);

  const removeListeners = useCallback(() => {
    playerRef.current.removeEventListener('timeupdate', onTimeUpdate, false);
    playerRef.current.removeEventListener('play', onPlay, false);
    playerRef.current.removeEventListener('pause', onPause, false);
    playerRef.current.removeEventListener('ended', onEnded, false);
  }, [playerRef, onEnded, onPlay, onPause, onTimeUpdate]);

  const unloadVideo = useCallback(() => {
    playerRef.current.pause();
    removeListeners();
    mp4Ref.current.src = undefined;
  }, [removeListeners]);

  const playVideo = async () => {
    try {
      await playerRef.current.play();
    } catch (error) {
      console.log('Could not play video');
    }
  };

  const onSetVolume = useCallback(
    (volume) => {
      dispatch(volumeActions.setVolume(volume));
      setVolume(volume);
    },
    [dispatch, setVolume]
  );

  const onPlayPauseClicked = () => {
    isPlaying ? playerRef.current.pause() : playerRef.current.play();
  };

  const onScrubStart = (e) => {
    setDragPause(isPlaying);
    dragPauseRef.current = isPlaying;
    if (isPlaying) {
      playerRef.current.pause();
    }
  };

  const onScrubStop = (e) => {
    if (dragPauseRef.current === true) {
      playerRef.current.play();
      dragPauseRef.current = false;
      setDragPause(false);
    }
  };

  const onUpdateScrubberPosition = (pos) => {
    playerRef.current.currentTime = playerRef.current.duration * pos;
    setPosition(pos);
  };

  // initial video load:
  useEffect(() => {
    if (src) {
      loadVideo(src);
      addListeners();
      playVideo();
    }
    return () => {
      unloadVideo();
    };
  }, [src, addListeners, unloadVideo]);

  // react to volume change:
  useEffect(() => {
    playerRef.current.volume = volume;
  }, [volume, playerRef]);

  return (
    <Container>
      <Video ref={playerRef} poster={thumbnail}>
        <source ref={mp4Ref} type='video/mp4' />
      </Video>
      <ProgressBar
        updateScrubberPosition={onUpdateScrubberPosition}
        onScrubStart={onScrubStart}
        onScrubStop={onScrubStop}
        width={469}
        loaded={percentLoaded}
        position={position}
      />
      <ControlsBar>
        <PlayPause isPlaying={isPlaying} onClick={onPlayPauseClicked} />
        <VolumeControl setVolume={onSetVolume} volume={volume} />
      </ControlsBar>
    </Container>
  );
};

const Container = styled.div`
  margin: 4px 0 0 0;
  width: 469px;
  padding: 0;
  display: block;
`;

const ControlsBar = styled.div`
  margin: 0;
  padding: 0;
  width: 469px;
  height: 41px;
  position: relative;
  display: block;
  ${dottedBackground}
`;

const Video = styled.video`
  width: 469px;
  height: 265px;
  margin: 0;
  padding: 0;
  display: block;
  outline: none;
`;

export default VideoPlayer;
