import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import history from './../history';
import { Title } from './../constants/styles.const';
import Credits from './../components/Credits.component';
import VideoPlayer from './../components/VideoPlayer.component';
import { getLevelUp } from './../utils';

const PortfolioDetail = (props) => {
  const {
    description,
    id,
    name,
    thumbnail,
    videoUrl,
    onNextClick,
    onPreviousClick,
    isNextDisabled,
    isPreviousDisabled,
    isShowing,
  } = props;

  const [display, setDisplay] = useState('none');

  const [displayClass, setDisplayClass] = useState('hide');

  const [isPaused, setIsPaused] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [openTimeout, setOpenTimeout] = useState();

  const [closeTimeout, setCloseTimeout] = useState();

  const open = useCallback(() => {
    if (isOpen) {
      return;
    }
    setIsPaused(false);
    setIsOpen(true);
    setDisplay('block');
    const timeout = setTimeout(function () {
      setDisplayClass('show');
    }, 0);
    setOpenTimeout(timeout);
  }, [isOpen]);

  const close = useCallback(() => {
    if (!isOpen) {
      return;
    }
    setIsPaused(true);
    setIsOpen(false);
    setDisplayClass('hide');
    const timeout = setTimeout(function () {
      setDisplay('none');
    }, 700);
    setCloseTimeout(timeout);
  }, [isOpen]);

  useEffect(() => {
    if (isShowing) {
      open();
    } else {
      close();
    }
    return () => {
      clearTimeout(openTimeout);
      clearTimeout(closeTimeout);
    };
  }, [isShowing, close, closeTimeout, openTimeout, open]);

  const onBackgroundClick = (e) => {
    close();
    setTimeout(() => {
      const levelUp = '/' + getLevelUp(props.location.pathname);
      history.push({
        pathname: levelUp,
        state: {
          from: history.location.pathname,
        },
      });
    }, 700);
  };

  return (
    <Background className={displayClass} display={display} onClick={onBackgroundClick}>
      <ItemContainer
        className={displayClass}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Title>{name}</Title>
        <VideoPlayer isPaused={isPaused} thumbnail={thumbnail} id={id} src={videoUrl} />

        <Info>
          <PreviousButton disabled={isPreviousDisabled} onClick={onPreviousClick} />
          <NextButton disabled={isNextDisabled} onClick={onNextClick} />
          <Credits alwaysShow={true} description={description} />
        </Info>
      </ItemContainer>
    </Background>
  );
};

const Info = styled.div`
  position: relative;
  min-height: 75px;
`;

const NavButton = styled.button`
  width: 52px;
  height: 52px;
  overflow: hidden;
  text-indent: -410px;
  display: block;
  position: absolute;
  top: 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  &:disabled {
    cursor: default;
    opacity: 0.1;
  }
`;

const PreviousButton = styled(NavButton)`
  background-image: url('/images/buttons.png');
  background-position: 0px -104px;
  &:hover {
    background-position: -52px -104px;
  }
  left: 0;

  &:disabled {
    &:hover {
      background-position: 0px -104px;
    }
  }
`;

const NextButton = styled(NavButton)`
  background-image: url('/images/buttons.png');
  background-position: 0px -156px;
  &:hover {
    background-position: -52px -156px;
  }
  &:disabled {
    &:hover {
      background-position: 0px -156px;
    }
  }
  right: 0;
`;

const ItemContainer = styled.div`
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 469px;
  padding: 20px;
  background-color: #121214;

  opacity: 0;
  transition: opacity 0.5s ease;

  &.show {
    opacity: 1;
    transition-delay: 0.2s;
  }
  &.hide {
    opacity: 0;
  }
`;

const Background = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => props.display};

  opacity: 0;
  transition: opacity 0.5s ease;

  &.show {
    opacity: 1;
  }
  &.hide {
    opacity: 0;
    transition-delay: 0.2s;
  }
`;

export default PortfolioDetail;
