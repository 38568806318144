import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { screenSizes, PageTitle } from './../constants/styles.const';

const AboutScreen = (props) => {
  return (
    <AboutContainer>
      <PageTitle>About Marc Sylvan</PageTitle>
      <Content>
        <ImageWrapper>
          <img alt='Marc Sylvan' src='/images/marc-about-page-2.jpg' />
        </ImageWrapper>
        <Description>
          <p>
            Marc Sylvan has forged an outstanding reputation as a composer and sound designer and is
            in high demand for television, commercials, film and video game projects. His many
            credits include the BAFTA award winning phenomenon{' '}
            <StyledLink to={'/portfolio/television/the-masked-singer'}>
              The Masked Singer
            </StyledLink>
            , the hit shows{' '}
            <StyledLink to={'/portfolio/television/starstruck'}>Starstruck</StyledLink>,{' '}
            <StyledLink to={'/portfolio/television/pointless'}>Pointless</StyledLink>,{' '}
            <StyledLink to={'/portfolio/television/the-million-pound-drop'}>
              The Million Pound Drop
            </StyledLink>
            ,{' '}
            <StyledLink to={'/portfolio/television/all-together-now'}>All Together Now</StyledLink>,{' '}
            <StyledLink to={'/portfolio/television/tipping-point'}>Tipping Point</StyledLink> and{' '}
            <StyledLink to={'/portfolio/television/total-wipeout'}>Total Wipeout</StyledLink> as
            well as an array of video game titles such as{' '}
            <StyledLink to={'/portfolio/games/sonic-sega-all-stars-racing'}>
              Sonic &amp; Sega All-Stars Racing
            </StyledLink>
            , <StyledLink to={'/portfolio/games/family-game-night-3'}>Family Game Night</StyledLink>
            , <StyledLink to={'/portfolio/games/human-heroes-einstein'}>Human Heroes</StyledLink>{' '}
            and <StyledLink to={'/portfolio/games/virtua-tennis'}>Virtua Tennis</StyledLink>
          </p>
          <p>
            Marc is published by{' '}
            <StyledExternalLink href={'https://www.fabermusic.com/'} target='_blank'>
              Faber Music
            </StyledExternalLink>
            , joining a team of esteemed composers including Evelyn Glennie, Carl Davis and Howard
            Goodall amongst others. He has also served on the Board and the Media Executive of{' '}
            <StyledExternalLink href={'https://ivorsacademy.com/'} target='_blank'>
              The Ivors Academy
            </StyledExternalLink>
          </p>
        </Description>
      </Content>
    </AboutContainer>
  );
};

const AboutContainer = styled.div`
  margin: 0 auto;
  text-align: center;

  @media (min-width: ${screenSizes.portrait}) {
    width: 714px;
    text-align: left;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 969px;
  }
`;

const Content = styled.div`
  margin-top: 28px;
`;

const ImageWrapper = styled.div`
  background-color: rgba(1, 4, 9, 0.6);
  padding: 20px;
  display: block;
  position: relative;
  img {
    display: block;
    width: 100%;
  }

  @media (min-width: ${screenSizes.portrait}) {
    vertical-align: top;
    margin-right: 10px;
    width: 299px;
    display: inline-block;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 427px;
  }
  @media (min-width: ${screenSizes.wide}) {
  }

  opacity: 0;
  animation: opacityFadeIn 1.2s ease-out 1 forwards;
  animation-delay: 0.4s;
`;

const StyledLink = styled(Link)`
  color: #b8c4d6;
  text-decoration: none;

  &:hover {
    color: #d7dfeb;
  }
  &.active {
    color: #d7dfeb;
  }
`;

const StyledExternalLink = styled.a`
  color: #b8c4d6;
  text-decoration: none;

  &:hover {
    color: #d7dfeb;
  }
  &.active {
    color: #d7dfeb;
  }
`;

const Description = styled.div`
  background-color: rgba(1, 4, 9, 0.3);
  padding: 12px 20px;
  display: block;
  position: relative;
  text-align: left;
  font-size: 0.9em;
  margin-top: 20px;
  color: #999999;

  opacity: 0;
  animation: opacityFadeIn 1.2s ease-out 1 forwards;
  animation-delay: 0.6s;

  p {
    margin-top: 0;
  }

  @media (min-width: ${screenSizes.portrait}) {
    vertical-align: top;
    margin-top: 0;
    margin-left: 10px;
    display: inline-block;
    width: 299px;
    text-align: left;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 427px;
  }
  @media (min-width: ${screenSizes.wide}) {
  }
`;

export default AboutScreen;
