const categories = {
  television: { id: '1', portfolioId: '1245531', uri: 'television', label: 'Television' },
  games: { id: '2', portfolioId: '1248321', uri: 'games', label: 'Games' },
  music: { id: '6', portfolioId: '1248322', uri: 'music', label: 'Music Releases' },
  film: { id: '4', portfolioId: '1248325', uri: 'film', label: 'Film & Animation' },
  commercials: { id: '3', portfolioId: '1248323', uri: 'commercials', label: 'Commercials' },
  digital: { id: '5', portfolioId: '1248326', uri: 'digital', label: 'Digital Media' }
};

export default categories;
