import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

import { screenSizes, PageTitle } from './../constants/styles.const';

const { REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID, REACT_APP_EMAILJS_USER_ID } =
  process.env;

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ContactScreen = (props) => {
  const [message, setMessage] = useState('');

  const [email, setEmail] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(false);

  const [isMessageValid, setIsMessageValid] = useState(false);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [isSending, setIsSending] = useState(false);

  const [isNotificationShowing, setIsNotificationShowing] = useState(false);

  const [notificationSuccess, setNotificationSuccess] = useState(false);

  const [notificationMessage, setNotificationMessage] = useState('');

  const [messageTimeout, setMessageTimeout] = useState();

  useEffect(() => {
    setIsEmailValid(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setIsMessageValid(message.length > 10);
  }, [message]);

  useEffect(() => {
    setIsSubmitDisabled(!isEmailValid || !isMessageValid || isSending);
  }, [isEmailValid, isMessageValid, isSending]);

  const onUpdateMessage = (e) => {
    setMessage(e.target.value);
  };

  const onUpdateEmail = (e) => {
    setEmail(e.target.value);
  };

  const clearForm = () => {
    setEmail('');
    setMessage('');
  };

  const notify = (success, message) => {
    setIsNotificationShowing(true);
    setNotificationSuccess(success);
    setNotificationMessage(message);
    const timeout = setTimeout(() => {
      setIsNotificationShowing(false);
    }, 5000);
    setMessageTimeout(timeout);
  };

  useEffect(() => {
    return () => {
      clearTimeout(messageTimeout);
    };
  }, [messageTimeout]);

  const onFormSubmitted = async (e) => {
    e.preventDefault();

    setIsSending(true);

    const emailErrorMessage =
      'Could not send email. Please use the email address provided instead or try again later.';
    try {
      const response = await emailjs.send(
        REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          user_email: email,
          message,
        },
        REACT_APP_EMAILJS_USER_ID
      );
      if (response.status === 200) {
        notify(true, 'Thank you, your rmessage was successfully sent.');
        clearForm();
      } else {
        notify(false, emailErrorMessage);
      }
    } catch (error) {
      notify(false, emailErrorMessage);
      console.log(error);
    }
    setIsSending(false);
  };

  return (
    <Container>
      <PageTitle>Contact</PageTitle>
      <Content>
        <ContactDetails>
          <ContactLink
            type='phone'
            label='Phone'
            link={'tel:+441732798581'}
            content={'+44 (0) 1732 798581'}
          />
          <ContactLink
            type='mobile'
            label='Mobile'
            link={'tel:+447949307311'}
            content={'+44 (0) 7949 307311'}
          />
          <ContactLink
            type='email'
            label='Email'
            link={'mailto:marc@marcsylvanmusic.co.uk'}
            content={'marc@marcsylvanmusic.co.uk'}
          />
        </ContactDetails>
        <ContactForm onSubmit={onFormSubmitted}>
          <InputWrapper>
            <EmailInput
              type='email'
              placeholder='Your email'
              value={email}
              required
              onChange={onUpdateEmail}
            />
            <MessageInput
              placeholder='Your message'
              required
              value={message}
              onChange={onUpdateMessage}
            />
            <SubmitButton disabled={isSubmitDisabled}>Submit</SubmitButton>
          </InputWrapper>
        </ContactForm>
      </Content>
      <Notification
        isShowing={isNotificationShowing}
        success={notificationSuccess}
        message={notificationMessage}
      />
    </Container>
  );
};

const Notification = ({ isShowing, success, message }) => {
  const className = isShowing ? 'show' : 'hide';
  return (
    <NotificationContainer success={success} className={className}>
      <p>{message}</p>
    </NotificationContainer>
  );
};

const NotificationContainer = styled.div`
  position: fixed;
  top: -120px;
  left: 0;
  width: 100%;
  min-height: 60px;
  background: ${(props) => (props.success ? '#6DD78D' : 'red')};
  color: white;

  transition: top 1s ease;

  &.show {
    opacity: 1;
  }

  p {
    padding: 5px;
  }
  &.show {
    top: 0px;
  }
  &.hide {
    top: -120px;
  }
`;

const ContactLink = ({ type, label, link, content }) => {
  return (
    <Wrapper>
      <Label type={type}>{label}: </Label>
      <A href={link}>{content}</A>
    </Wrapper>
  );
};

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
  margin: 0 30px;
`;

const Content = styled.div`
  margin-top: 25px;

  opacity: 0;
  animation: opacityFadeIn 1.2s ease-out 1 forwards;
  animation-delay: 0.4s;
`;

const ContactDetails = styled.div`
  margin-right: 20px;
  @media (min-width: ${screenSizes.portrait}) {
    width: 400px;
    display: inline-block;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 380px;
  }
`;

const Wrapper = styled.p`
  position: relative;
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  margin: 10px 0;
`;

const getBackgroundPosition = (props) => {
  switch (props.type) {
    case 'phone':
      return '-24px 0';
    case 'mobile':
      return '-48px 0';
    case 'email':
    default:
      return '0 0';
  }
};

const Label = styled.span`
  &:before {
    display: inline-block;
    content: ' ';
    width: 24px;
    height: 25px;
    margin-right: 14px;
    background-image: url('/images/contact-icons.png');
    background-position: ${getBackgroundPosition};
    background-repeat: no-repeat;
    position: relative;
    top: 5px;
  }
`;

const A = styled.a`
  color: #b8c4d6;
  text-decoration: none;

  &:hover {
    color: #d7dfeb;
  }
  &.active {
    color: #d7dfeb;
  }
`;

const ContactForm = styled.form`
  position: relative;
  vertical-align: top;
  display: block;
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);

  opacity: 0;
  animation: opacityFadeIn 1.2s ease-out 1 forwards;
  animation-delay: 0.6s;

  @media (min-width: ${screenSizes.portrait}) {
    margin: 10px auto 0;
    width: 370px;
  }
  @media (min-width: ${screenSizes.landscape}) {
    margin-left: 20px;
    display: inline-block;
    width: 360px;
  }
`;

const InputWrapper = styled.div`
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
`;

const EmailInput = styled.input`
  display: block;
  padding: 8px 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
`;

const MessageInput = styled.textarea`
  margin-top: 20px;
  display: block;
  padding: 8px 10px;
  width: 100%;
  height: 160px;
  box-sizing: border-box;
  font-size: 1em;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
`;

const SubmitButton = styled.button`
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #cccccc;
  padding: 10px 20px;
  display: inline-block;
  margin: 20px auto 0;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    &:disabled {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  &:disabled {
    background-color: rgba(0, 0, 0, 0.8);
    color: #777777;
    cursor: default;
    opacity: 0.5;
  }
  font-size: 1em;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
`;

export default ContactScreen;
