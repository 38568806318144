import React, { useRef, useCallback } from 'react';
import { LoadingState } from 'api-resource-collection';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { uuid } from 'uuidv4';
import moize from 'moize';

import history from './../history';
import { portfolio } from './../state/apiCollections';
import PortfolioItem from './../components/PortfolioItem.component';
import LoadingSpinner from './../components/LoadingSpinner.component';
import { getURLSegments, slugify, isMobile } from './../utils';
import categoriesDictionary from './../categories.const';
import { screenSizes, PageTitle } from './../constants/styles.const';
import PortfolioDetail from './PortfolioDetail.component';

const NEXT = 'NEXT';

const PREVIOUS = 'PREVIOUS';

const PortfolioScreen = (props) => {
  const dispatch = useDispatch();

  const [nav, category, portfolioItemUri] = getURLSegments(history.location.pathname);

  const isPortfolioItemShowing = portfolioItemUri !== undefined;

  const selectedCategory = categoriesDictionary[category];

  const portfolioCollection = portfolio[selectedCategory.uri];

  const selectedPortfolio = useSelector(portfolioCollection.useCollection);

  const isMob = isMobile();

  const mobilePlayerRef = useRef();

  const mobileMp4Ref = useRef();

  const onMobVideoClosed = useCallback(() => {
    mobilePlayerRef.current.removeEventListener('webkitendfullscreen', onMobVideoClosed, false);
  }, []);

  if (selectedPortfolio.items.loadingState === LoadingState.INITIAL) {
    dispatch(portfolioCollection.getItems());
  }

  if (selectedPortfolio.isLoading) {
    return <LoadingSpinner />;
  }

  const items = selectedPortfolio.items.data.sort((a, b) => {
    return a.index < b.index ? -1 : 1;
  });

  const selectedItem = getSelectedItem(portfolioItemUri, items);

  const title = `Portfolio / ${selectedCategory.label}`;

  const playMobileVideo = async (src) => {
    mobileMp4Ref.current.src = src;
    await mobilePlayerRef.current.load();
    try {
      mobilePlayerRef.current.addEventListener('webkitendfullscreen', onMobVideoClosed, false);
      await mobilePlayerRef.current.play();
    } catch (error) {
      // TODO: handle this better
      console.log('Could not play video');
    }
  };

  const onItemSelected = (itemUri) => {
    if (isMob) {
      const selectedItem = getSelectedItem(itemUri, items);
      playMobileVideo(selectedItem.videoUrl);
    } else {
      history.push(`${history.location.pathname}/${itemUri}`);
    }
  };

  const changePortfolioItem = (direction) => {
    const indexInc = direction === NEXT ? 1 : -1;
    const newIndex = selectedItem.index + indexInc;
    const newItem = items[newIndex];
    if (newItem) {
      history.push({
        pathname: `/${nav}/${category}/${slugify(newItem.name)}`,
        state: {
          from: history.location.pathname,
        },
      });
    }
  };

  const isPreviousDisabled = selectedItem && selectedItem.index === 0;

  const isNextDisabled = selectedItem && selectedItem.index === items.length - 1;

  const lastPage = history.location.state && history.location.state.from;

  const avoidFade = lastPage || history.location.pathname.split('/').length >= 4;

  return (
    <PortfolioContainer>
      <PageTitle>{title}</PageTitle>

      <PortfolioItems useFade={!avoidFade} items={items} onItemSelected={onItemSelected} />

      {isMob && (
        <Video ref={mobilePlayerRef}>
          <source ref={mobileMp4Ref} type='video/mp4' />
        </Video>
      )}

      <PortfolioDetail
        location={props.location}
        isShowing={isPortfolioItemShowing}
        {...selectedItem}
        onNextClick={changePortfolioItem.bind(null, NEXT)}
        onPreviousClick={changePortfolioItem.bind(null, PREVIOUS)}
        isNextDisabled={isNextDisabled}
        isPreviousDisabled={isPreviousDisabled}
      />
    </PortfolioContainer>
  );
};

const PortfolioItems = moize(({ items, onItemSelected, useFade }) => {
  return (
    <PortfolioItemsContainer>
      {items.map((item, index) => {
        return (
          <PortfolioItem
            useFade={useFade}
            index={index}
            onSelect={onItemSelected}
            key={uuid()}
            {...item}
          />
        );
      })}
    </PortfolioItemsContainer>
  );
});

const Video = styled.video`
  width: 1px;
  height: 1px;
  position: absolute;
  bottom: 100px;
`;

const getSelectedItem = (uri, items) => {
  return items.find((item) => {
    return slugify(item.name) === uri;
  });
};

const PortfolioItemsContainer = styled.div`
  margin: 0 auto;
  text-align: center;

  @media (min-width: ${screenSizes.portrait}) {
    width: 714px;
    text-align: left;
  }
  @media (min-width: ${screenSizes.landscape}) {
    width: 969px;
  }
  @media (min-width: ${screenSizes.wide}) {
    width: 1296px;
  }
`;

const PortfolioContainer = styled.div`
  color: white;
`;

export default PortfolioScreen;
